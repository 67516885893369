/* eslint-disable no-unused-vars */
import "./App.css";
import Sidebar from "./layout/Sidebar/Sidebar";

import ContentTop from "./layout/ContentTop/ContentTop";

import { useEffect, useState } from "react";
import { Routes, Route, Outlet } from "react-router-dom";

import UserSessionContext from "./context/UserSessionContext";

import Login from "./components/Login/Login";

import { jwtDecode } from "jwt-decode";

import Spinner from "./utils/Spinner";

import routes from "./utils/routes";
import ProtectedRoute from "./utils/ProtectedRoute";
import NotFound from "./utils/NotFound";
import SidebarMobile from "./layout/Sidebar/SidebarMobile";
import { ToastContainer, toast } from "react-toastify";
import CompanyContext from "./context/CompanyContext";
function App() {
  const [pos, setPos] = useState(false);
  const [token, setToken] = useState();
  const [company, setCompany] = useState();
  const [sidebarx, setSidebarx] = useState(false);
  const [printerSize, setPrinterSize] = useState("A4");
  const [flags, setFlags] = useState({
    enableSound: false,
    AllowItemwiseDiscount: true,
    DisplayAvailableStock: true,
    AllowOutOfStockItemsSale: true,
    AllowRateModify: true,
    AllProductShow: true,
    IrdDate: true,
    NepaliDate:false,
    TemplateFormat: false,
    QrGenerator: false,
    InventoryDate: true,
    NumberOfCopy: true,
    Tax: true,
    MultiplePrintSize: false,
  });

  useEffect(() => {
    const _token = window.localStorage.getItem("RETAIL_TOKEN");
    const storedTokenExpiry = localStorage.getItem("tokenExpiry");
    const storedCompany = JSON.parse(localStorage.getItem("company"));
    if (_token && storedTokenExpiry) {
      const expiryTime = parseInt(storedTokenExpiry, 10);
      const currentTime = new Date().getTime();

      // Check if the token has expired
      if (currentTime > expiryTime) {
        // Clear local storage if the token has expired
        localStorage.removeItem("company");
        handleLogOut();
      }
    }
    if (_token === undefined || _token === null) {
      setToken(false);
      localStorage.removeItem("company");
      handleLogOut();
    } else {
      setToken(_token);
      setCompany(storedCompany);
      //getCompanies(_token);
    }
  }, [token]);
  const decodeToken = (token) => {
    try {
      return jwtDecode(token);
    } catch (error) {
      return null;
    }
  };

  const save_token = (e) => {
    window.localStorage.setItem("RETAIL_TOKEN", e);
    decodeToken(e);
    const tokenExpiry = new Date().getTime() + 3600000; // Assuming the token is valid for 1 hour

    localStorage.setItem("tokenExpiry", tokenExpiry.toString());
    setToken(e);
  };

  const handleLogOut = () => {
    window.localStorage.removeItem("RETAIL_TOKEN");
    window.localStorage.removeItem("user");
    window.localStorage.removeItem("company");
    window.localStorage.removeItem("fiscal");
    window.localStorage.removeItem("tokenExpiry");
    sessionStorage.removeItem("fiscal");
    setToken(false);
  };
  if (token === undefined) {
    return <Spinner msg="Authenticating..." />;
  }

  if (token === false) {
    return (
      <div>
        <ToastContainer />
        <Login
          setToken={save_token}
          setPos={setPos}
          setCompany={setCompany}
        ></Login>
      </div>
    );
  }

  if (company === undefined) {
    return <Spinner msg=" Company Initializing..." />;
  }

  return (
    <div className="App">
      <UserSessionContext.Provider
        value={{
          token: token,
          handleLogOut: handleLogOut,
        }}
      >
        <CompanyContext.Provider
          value={{
            company: company,
            setCompany: setCompany,
            setPos: setPos,
            pos: pos,
            flags: flags,
            setFlags: setFlags,
            printerSize: printerSize,
            setPrinterSize: setPrinterSize,
          }}
        >
          <ToastContainer />
          <div>
            <Sidebar
              setPos={setPos}
              pos={pos}
              token={token}
              sidebarx={sidebarx}
            />
          </div>
          <div className="rounded main-content ">
            <ContentTop
              setPos={setPos}
              pos={pos}
              company={company}
              setSidebarx={setSidebarx}
              sidebarx={sidebarx}
            />

            <div className="main-container">
              <Routes>
                <Route
                  path="/login"
                  element={
                    <Login setToken={save_token} setPos={setPos} exact={true} />
                  }
                />

                <Route element={<ProtectedRoute />}>
                  <Route path="/" element={<Outlet />}>
                    {routes.map((route, index) => (
                      <Route
                        key={index}
                        element={route.element}
                        path={route.path}
                        exact={route.component}
                      />
                    ))}
                  </Route>
                </Route>

                <Route path="*" element={<NotFound />} />
              </Routes>
        
            </div>
            <div className="side">
            <SidebarMobile />
          </div>
          </div>
        
        </CompanyContext.Provider>
      </UserSessionContext.Provider>
    </div>
  );
}

export default App;
