/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
//import { iconsImgs } from "../../utils/images";
import "./ContentTop.css";
import { useContext, useEffect, useState } from "react";
import { SidebarContext } from "../../context/sidebarContext";
import { RxAvatar } from "react-icons/rx";
import { AiOutlineFullscreen } from "react-icons/ai";
import { RiFullscreenExitFill } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";

import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { ArrowLeftRight, Bell, CircleUser, Expand, House, Lock, Menu, Shrink } from 'lucide-react';

import CompanyContext from "../../context/CompanyContext";
import Dropdown from "react-bootstrap/Dropdown";
import { CiSettings } from "react-icons/ci";

import { BiLogOut } from "react-icons/bi";
import { FaBell } from "react-icons/fa";


import { computeStyles } from "@popperjs/core";
import UserSessionContext from "../../context/UserSessionContext";
import ChangePassword from "./ChangePassword";
import SweetAlert from 'react-bootstrap-sweetalert';

import { useLocation } from "react-router-dom";


const ContentTop = ({ setPos, pos, company, setSidebarx, sidebarx }) => {
  const {isSidebarOpen, toggleSidebar } = useContext(SidebarContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [showDashboard, setShowDashboard] = useState(false);
  const companyContext = useContext(CompanyContext);
  const userSessionContext = useContext(UserSessionContext);
  const [isNewPopup, setIsNewPopup] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [showAccountDropdown, setShowAccountDropdown] = useState(false);
  const [showNotificationDropdown, setShowNotificationDropdown] = useState(false);

  const handleicon = () => {
   
      if (isSidebarOpen == false) {
        setShowDashboard(false)
        setPos(true);
        toggleSidebar();
      }
   
  };

  const fullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
      setIsFullscreen(true);
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
        setIsFullscreen(false);
      }
    }
  };

  const handleLogout = () => {
    setPos(false);
    userSessionContext.handleLogOut();
    // window.location.reload()
  };

  const handleClose = () => {
    setIsNewPopup(false);
  };

  const handleSweet = () => {
    setShowAlert(true);
  };

  const handleSetting = () => {
    navigate("/retail/invoices1");
    setPos(false);
  };

  const handleAccountItemClick = (action) => {
    setShowAccountDropdown(false);
    if (action === 'changePassword') {
      setIsNewPopup(true);
    } else if (action === 'settings') {
      handleSetting();
    } else if (action === 'logout') {
      handleSweet();
    }
  };

  const handleDropdownToggle = (dropdownType) => {
    if (dropdownType === 'notification') {
      setShowNotificationDropdown(!showNotificationDropdown);
      setShowAccountDropdown(false);
    } else if (dropdownType === 'account') {
      setShowAccountDropdown(!showAccountDropdown);
      setShowNotificationDropdown(false);
    }
  };

  return (
    <div className="main-content-top">
      <div className="content-top-left">
        {location.pathname === '/retail/page' ? "" : (
          <>
          <div className="display-header">
          <button style={{ border: 'none', cursor: 'pointer', backgroundColor: 'transparent' }}>
              <Menu size={28} color="#2c2a2a" strokeWidth={0.95} onClick={toggleSidebar} />
            </button>
          

               <button style={{ border: 'none', cursor: 'pointer', backgroundColor: 'transparent' }}>
               <ArrowLeftRight size={28} color="#2c2a2a" strokeWidth={0.95} onClick={() => setSidebarx(!sidebarx)} />
              </button>
              </div>
          </>
        )}
        <h4 className="company-name" style={{color:"#192dc8"}}>
          {companyContext.company.name ? companyContext.company.name : null}
        </h4>
      </div>

      <div className="content-top-btns d-flex justify-content-between gap-3 px-3">
        {pos === true ?
          <button
            className="dashboard-icon"
            onClick={() => setShowDashboard(true)}
          >
            <House color="#192dc8" />
          </button>:  
          
          <Link  to={'./retail/page'}> <button
          style={{ border:'1px solid #192dc8', borderRadius:'50% ',color:"#192dc8",backgroundColor: "#6571ff",padding: "1px 1px"}}
          className="dashboard-icon1"
          onClick={ handleicon}
        >
 POS
         
        </button></Link>
        }

        <SweetAlert
          success
          showCancel
          cancelBtnStyle={{ backgroundColor: 'red', color: '#fff', textDecoration: 'none' }}
          confirmBtnStyle={{ backgroundColor: 'green', color: '#fff' }}
          cancelBtnText="Cancel"
          confirmBtnText="Confirm"
          onConfirm={() => {
            setShowDashboard(false);
            setPos(false);
            navigate("/");
          }}
          onCancel={() => setShowDashboard(false)}
          show={showDashboard}
          focusCancelBtn
        >
          You sure you want to go to dashboard?
        </SweetAlert>

        {isFullscreen ? (
        
         
            <button type="button" className="pointer ">
            <Shrink color="#122C53"  onClick={fullScreen} />
            </button>
   
        ) : (
    
          
          
            <button type="button" className="pointer ">
              <Expand color="#192dc8" size={23}  onClick={fullScreen} />
            </button>
          
        )}

        <Dropdown show={showNotificationDropdown} onToggle={() => handleDropdownToggle('notification')}>
          <Dropdown.Toggle
            as={(props) => (
            
              
                <button {...props} className="notification-bt">
                <Bell color="#192dc8" />
                </button>
           
            )}
          />

          <Dropdown.Menu style={{ left: 'auto', right: '30px', width: '200px', height: '200px' }}>
            <span className="px-3">Notification</span>
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown show={showAccountDropdown} onToggle={() => handleDropdownToggle('account')}>
          <Dropdown.Toggle
            as={(props) => (
              
                <button {...props} className="notification-btn-dot">
                <CircleUser color="#192dc8" />
                </button>
            
            )}
          />

          <Dropdown.Menu style={{ left: 'auto', right: '30px', width: '200px' }}>
            <div>
              <div className="px-3">
                <h4 className="p-0 m-0">Sign In as</h4>
              </div>
              <p className="px-3 text-lowercase">{localStorage.getItem("user")}</p>
              <hr className="my-0" />
              <div type="button" className="btn btn-light d-flex justify-content-start px-2 py-1 w-100" onClick={() => handleAccountItemClick('changePassword')}>
              <Lock style={{ marginRight: '10px' }} /> Change Password
              </div>
              <div type="button" className="btn btn-light d-flex justify-content-start px-2 py-1 w-100" onClick={() => handleAccountItemClick('settings')}>
                <CiSettings size={"30px"} style={{ marginRight: '10px' }} /> Setting
              </div>
              <div type="button" className="btn btn-light d-flex justify-content-start px-2 py-1 w-100 flex-end" onClick={() => handleAccountItemClick('logout')}>
                <BiLogOut size={"30px"} style={{ marginRight: '10px' }} /> Log Out
              </div>
            </div>
          </Dropdown.Menu>
        </Dropdown>

        <Modal
          size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={isNewPopup}
          onHide={handleClose}
        >
          <Modal.Header closeButton>Do You Want Change Password ?</Modal.Header>
          <Modal.Body style={{ overflowX: 'scroll', width: '100%' }}>
            <ChangePassword />
          </Modal.Body>
        </Modal>

        <SweetAlert
          success
          showCancel
          cancelBtnStyle={{ backgroundColor: 'red', color: '#fff', textDecoration: 'none' }}
          cancelBtnText="Cancel"
          confirmBtnStyle={{ backgroundColor: 'green', color: '#fff' }}
          confirmBtnText="Confirm"
          onConfirm={handleLogout}
          onCancel={() => setShowAlert(false)}
          show={showAlert}
          focusCancelBtn
        >
          Are you sure you want to Logout?
        </SweetAlert>
      </div>
    </div>
  );
};

export default ContentTop;
