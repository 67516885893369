import { BracesIcon, Computer, Home, IterationCcwIcon, LayoutPanelTop, PoundSterling, ReceiptPoundSterling ,BookUp2,AlignVerticalDistributeCenter} from "lucide-react";
import { iconsImgs } from "../utils/images";


export const navigationLinks = [
  { id: 1, title: "Home", image: iconsImgs.homes, path: "/" },
  { id: 2, title: "POS", image: iconsImgs.poss, path: "/retail/page" },
  {
    id: 3,
    title: "Invoice",
    image: iconsImgs.inventory,
    path: "/retail/Report",
  },
  {
    id: 4,
    title: "Item Master",
    image: iconsImgs.control,
    path: "/retail/master",
  },

  { id: 5, title: "Report", image: iconsImgs.reports, path: "/retail/invoices" },
  

  {
    id: 6,
    title: "Loyality",
    image: iconsImgs.wealth,
    path: "/retail/Loyality",
  },

];


export const navigationLink = [
  { id: 1, title: "Home", image: Home, path: "/" },
  { id: 2, title: "POS", image:BookUp2, path: "/retail/page" },
  {
    id: 3,
    title: "Invoice",
    image: Computer,
    path: "/retail/Report",
  },
  {
    id: 4,
    title: "Item Master",
    image:  AlignVerticalDistributeCenter ,
    path: "/retail/master",
  },

  { id: 5, title: "Reports", image: ReceiptPoundSterling, path: "/retail/invoices" },
  

  // {
  //   id: 6,
  //   title: "Loyality",
  //   image: LayoutPanelTop,
  //   path: "/retail/Loyality",
  // },

];